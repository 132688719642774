// eslint-disable-next-line simple-import-sort/imports
import React, { memo, useCallback } from 'react';
// libs
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'context/AuthContext';
import routes from 'routes';
import { getDevicesApi } from '../../../../api/getDevicesApi';
// icons
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// icons
import { ReactComponent as LogotypeIcon } from 'assets/icons/logotype.svg';
// styles
import { MenuButton } from '../Header/styled';
import { Divider, Footer, Header, NavLink, NavLinks, Wrapper } from './styled';
import { primary } from 'themes/colors';
// types
import { NavigationMenuProps } from './types';
import { LogoutInputProps } from 'api/types';
import { logoutUserFn } from 'api/authApi';


enum ACCESS_LEVEL {
  Board = 'Board',
  Employee = 'Employee',
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ handleClose }) => {
  const { user, setUser } = useAuth();
  const queryClient = useQueryClient();
  const { data } = useQuery(
    ['devices'],
    () => user && getDevicesApi({ cc: user.comcode, tk: 'aeb5d0e6-b574-43c5-b2f5' }),
  );

  const localStorageData = localStorage.getItem('user');
  const userData = localStorageData && JSON.parse(localStorageData);

  const {
      mutate: logoutUser,
      isLoading,
      error,
    } = useMutation((userData: LogoutInputProps) => logoutUserFn(userData), {
      onSuccess: (data) => {
        if (data) {
          setUser(null);
          localStorage.removeItem('user');
          document.body.style.overflow = ''; // unlock scrolling after click on logout account inside hamburger menu
          queryClient.clear();
        }
      },
    });

  const handleLogOut = useCallback(() => {
    logoutUser({ appUserId: userData.appUserId, token: userData.token });
  }, [logoutUser, userData.appUserId, userData.token]);
  

  return (
    <Wrapper>
      <Header>
        <Link cypress-id='navigationMenu-logotype' to='/'>
          <LogotypeIcon />
        </Link>

        <MenuButton cypress-id='navigationMenu-closeButton' onClick={handleClose}>
          <CloseIcon />
        </MenuButton>
      </Header>

      <NavLinks>
        <NavLink cypress-id='navigationMenu-home' to='/'>
          Home
        </NavLink>
        <NavLink cypress-id='navigationMenu-myProperty' to={routes.myProperty.index}>
          My Property
        </NavLink>
        <NavLink cypress-id='navigationMenu-publicDocuments' to={routes.publicDocuments}>
          Public Documents
        </NavLink>
        <NavLink cypress-id='navigationMenu-associationDocuments' to={routes.associationDocuments}>
          Association Documents
        </NavLink>
        <NavLink cypress-id='navigationMenu-communityInformation' to={routes.communityInformation}>
          Community Information
        </NavLink>
        <NavLink cypress-id='navigationMenu-keyPersonnel' to={routes.keyPersonnel}>
          Key Personnel
        </NavLink>

        {data && (
          <NavLink cypress-id='navigationMenu-deviceRequest' to={routes.deviceRequest}>
            Device Request
          </NavLink>
        )}

        <NavLink cypress-id='navigationMenu-allAboutMe' to={routes.allAboutMe}>
          All About Me
        </NavLink>
        <NavLink cypress-id='navigationMenu-ciaServices' to={routes.ciaServices}>
          C.I.A. Services
        </NavLink>
        <NavLink cypress-id='navigationMenu-contactUs' to={routes.contactUs}>
          Contact Us
        </NavLink>

        <Divider />

        {userData.accessLevel === ACCESS_LEVEL.Board && (
          <NavLink cypress-id='navigationMenu-boardMemberPortal' to={routes.boardMemberPortal}>
            Board Member Portal
          </NavLink>
        )}
        {userData.accessLevel === ACCESS_LEVEL.Employee && (
          <NavLink cypress-id='navigationMenu-lookLikeAccount' to={routes.lookLikeAnAccount}>
            Look Like an Account
          </NavLink>
        )}

        {Object.keys(ACCESS_LEVEL).includes(userData.accessLevel) && <Divider />}

        <NavLink cypress-id='navigationMenu-logOut' to='/' onClick={handleLogOut}>
          Log Out
        </NavLink>
      </NavLinks>

      <Footer>
        <Typography align='center' color={primary[300]}>
          © 2011-2022 C.I.A. Services Inc. All rights reserved.
        </Typography>
      </Footer>
    </Wrapper>
  );
};

export default memo(NavigationMenu);
