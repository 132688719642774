// material-ui
import { Box, Button as MaterialButton, styled, Typography } from '@mui/material';
import { User } from 'api/types';
// styles
import { grey } from 'themes/colors';

export const AccountInfo = styled(Box)<{ isShowSwitchButton: boolean, user: User }>`
  border-radius: 8px 8px 0 0;
  border-width: 1px 1px 0px;
  border-color: ${grey[300]};
  border-style: solid;
  background-color: #ffffff;
  padding: 16px 24px 24px;

  ${({ isShowSwitchButton }) =>
    isShowSwitchButton ||
    `
    border-radius: 8px;
    border-bottom-width: 1px;
  `}

  ${({ user }) =>
      user?.properties?.length <= 1 &&
    `
    border-radius: 8px;
    border-bottom-width: 1px;
  `}

`;

export const Title = styled(Typography)`
  color: ${grey[900]};
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const Label = styled(Typography)`
  color: ${grey[500]};
  margin-bottom: 4px;
  font-weight: 500;
`;

export const Value = styled(Typography)`
  color: ${grey[900]};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

export const Button = styled(MaterialButton)`
  font-size: 16px;
  border-radius: 0 0 8px 8px;
  box-shadow: ${({ theme }) => theme.cShadows.xs};

  svg {
    margin-left: 8px;
  }
`;
